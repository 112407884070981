import * as React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import "./headerabout.scss"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"


const HeaderAbout = ({ className }) => {
    const { t } = useTranslation("about", { keyPrefix: "HeaderAbout" });
    return (
        <section className={(className ? className + " " : "") + "header-about"}>
            <Container fluid>
                <Row className="back-header-about">
                    <div className="max-container-sections">
                        <Col className="list-about" md={6} lg={6}>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#ourculture">{t("Our Culture")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#ourconstitution">{t("Our Constitution")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#annualsummits">{t("Annual Summits")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#wethepeople">{t("We, the People")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#counciloftitans">{t("Council of Titans")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#leadership">{t("Leadership")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#givingBack">{t("Giving Back To Our Communities")}</a></h4>
                            </Col>
                            <Col className="line-container" xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <h4 className="header-square white-light md-large"><a href="#companylife">{t("Company Life")}</a></h4>
                            </Col>
                        </Col>
                        <Col className="back-header-text-about" xs={12} sm={12} md={6} lg={6}>
                            <span className="about">{t("We are a benefit corporation")}</span><br></br><br></br>
                            <p>
                                {t("And as such we are legally accountable to all our stakeholders (employees, clients, suppliers, etc.), not only to our shareholders.")}
                            </p><br></br>
                        </Col>
                    </div>
                </Row>
            </Container>
            <Link className="goto-search-arrow d-lg-block" to="#ourculture">
                <span className="icon-arrow-down arw-white"> </span>
            </Link>
        </section>
    )
}

export default HeaderAbout
