import React, { useState } from "react"
import { Button, Container, Row } from 'react-bootstrap'
import ItemsCarousel from 'react-items-carousel';
import "./givingback.scss"

import school from "../../images/givingBack/uruguay-school.png";
import soccer from "../../images/givingBack/tandil-soccer.png";
import resistenciaSupport from "../../images/givingBack/resistencia-support.png";
import { useTranslation } from "gatsby-plugin-react-i18next"

const GivingBack = ({ className }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const { t } = useTranslation("about", { keyPrefix: "GivingBack" })
    const [toggleSchool, setToggleSchool] = React.useState(false);
    const [toggleSoccer, setToggleSoccer] = React.useState(false);
    const [toggleResistenciaSupport, setToggleResistenciaSupport] = React.useState(false);

    const schoolToggler = () => {
        toggleSchool ? setToggleSchool(false) : setToggleSchool(true);
    }

    const soccerToggler = () => {
        toggleSoccer ? setToggleSoccer(false) : setToggleSoccer(true);
    }

    const resistenciaSupportToggler = () => {
        toggleResistenciaSupport ? setToggleResistenciaSupport(false) : setToggleResistenciaSupport(true);
    }

    const chevronWidth = 100;

    return (
        <section id="givingBack" className={(className ? className + " " : "") + "givingBack"}>
            <Container fluid className="givingBackLaptop">
                <div className="max-container-big">
                    <Row className="">
                        <h6 className="header-square blue md-large lg-large">{t("Giving Back To Our Communities")}</h6>
                        <div className="carouselContainer">
                            <ItemsCarousel
                                requestToChangeActive={setActiveItemIndex}
                                activeItemIndex={activeItemIndex}
                                numberOfCards={3}
                                gutter={120}
                                leftChevron={<i className="bi bi-chevron-left" />}
                                rightChevron={<i className="bi bi-chevron-right" />}
                                outsideChevron={true}
                                alwaysShowChevrons={true}
                                chevronWidth={chevronWidth}
                                infiniteLoop={true}
                            >
                                <div className="card small-text">
                                    <img alt="charity :D" src={school} />
                                    <h4 className="card-title">{t("School in Uruguay")}</h4>
                                    <p className={(toggleSchool ? "text-card-full" : "text-card")}>{t("As part of our Give Back to the Community Program")}</p>
                                    <span className="readmore-card" onClick={schoolToggler} aria-hidden="true"> {t("Read")} {(toggleSchool ? t("less") : t("more"))}</span>
                                </div>
                                <div className="card small-text">
                                    <img alt="charity :D" src={soccer} />
                                    <h4 className="card-title">{t("Soccer team in Tandil")}</h4>
                                    <p className={(toggleSoccer ? "text-card-full" : "text-card")}>{t("In Tandil, Argentina, before the end of the year")}</p>
                                    <span className="readmore-card" onClick={soccerToggler} aria-hidden="true"> {t("Read")} {(toggleSoccer ? t("less") : t("more"))}</span>
                                </div>
                                <div className="card"><img alt="charity :D" src={resistenciaSupport} />
                                    <h4 className="card-title">{t("Communities support in Resistencia")}</h4>
                                    <p className={(toggleResistenciaSupport ? "text-card-full" : "text-card")}>{t("A few weeks ago our colleagues from Resistencia")}</p>
                                    <span className="readmore-card" onClick={resistenciaSupportToggler} aria-hidden="true">{t("Read")} {(toggleResistenciaSupport ? t("less") : t("more"))}</span>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </Row>
                </div>
            </Container>
            <Container fluid className="givingBackTablet">
                <div className="max-container-big">
                    <Row className="">
                        <h6 className="header-square blue md-large lg-large">{t("Giving Back To Our Communities")}</h6>
                        <div className="carouselContainer">
                            <ItemsCarousel
                                requestToChangeActive={setActiveItemIndex}
                                activeItemIndex={activeItemIndex}
                                numberOfCards={2}
                                gutter={120}
                                leftChevron={<i className="bi bi-chevron-left" />}
                                rightChevron={<i className="bi bi-chevron-right" />}
                                outsideChevron={true}
                                alwaysShowChevrons={true}
                                chevronWidth={chevronWidth}
                                infiniteLoop={true}
                            >
                                <div className="card small-text">
                                    <img alt="charity :D" src={school} />
                                    <h4 className="card-title">{t("School in Uruguay")}</h4>
                                    <p className={(toggleSchool ? "text-card-full" : "text-card")}>{t("As part of our Give Back to the Community Program")}</p>
                                    <span className="readmore-card" onClick={schoolToggler} aria-hidden="true">{t("Read")} {(toggleSchool ? t("less") : t("more"))}</span>
                                </div>
                                <div className="card small-text">
                                    <img alt="charity :D" src={soccer} />
                                    <h4 className="card-title">{t("Soccer team in Tandil")}</h4>
                                    <p className={(toggleSoccer ? "text-card-full" : "text-card")}>{t("In Tandil, Argentina, before the end of the year")}</p>
                                    <span className="readmore-card" onClick={soccerToggler} aria-hidden="true">{t("Read")} {(toggleSoccer ? t("less") : t("more"))}</span>
                                </div>
                                <div className="card"><img alt="charity :D" src={resistenciaSupport} />
                                    <h4 className="card-title">{t("Communities support in Resistencia")}</h4>
                                    <p className={(toggleResistenciaSupport ? "text-card-full" : "text-card")}>{t("A few weeks ago our colleagues from Resistencia")}</p>
                                    <span className="readmore-card" onClick={resistenciaSupportToggler} aria-hidden="true">{t("Read")} {(toggleResistenciaSupport ? t("less") : t("more"))}</span>
                                </div>
                            </ItemsCarousel>
                        </div>
                    </Row>
                </div>
            </Container>
            <div className="givingBackMobile max-container-big">
                <h6 className="header-square blue md-large lg-large">{t("Giving Back To Our Communities")}</h6>
                <div className="card small-text">
                    <img alt="charity :D" src={school} />
                    <h4 className="card-title">{t("School in Uruguay")}</h4>
                    <p className={(toggleSchool ? "text-card-full" : "text-card")}>{t("As part of our Give Back to the Community Program")}</p>
                    <span className="readmore-card" onClick={schoolToggler} aria-hidden="true">{t("Read")} {(toggleSchool ? t("less") : t("more"))}</span>
                </div>
                <div className="card small-text">
                    <img alt="charity :D" src={soccer} />
                    <h4 className="card-title">{t("Soccer team in Tandil")}</h4>
                    <p className={(toggleSoccer ? "text-card-full" : "text-card")}>{t("In Tandil, Argentina, before the end of the year")}</p>
                    <span className="readmore-card" onClick={soccerToggler} aria-hidden="true">{t("Read")} {(toggleSoccer ? t("less") : t("more"))}</span>
                </div>
                <div className="card"><img alt="charity :D" src={resistenciaSupport} />
                    <h4 className="card-title">{t("Communities support in Resistencia")}</h4>
                    <p className={(toggleResistenciaSupport ? "text-card-full" : "text-card")}>{t("A few weeks ago our colleagues from Resistencia")}</p>
                    <span className="readmore-card" onClick={resistenciaSupportToggler} aria-hidden="true">{t("Read")} {(toggleResistenciaSupport ? t("less") : t("more"))}</span>
                </div>
                {/* <Button variant="success" type="submit" >{t("Load More")}</Button> */}
            </div>
        </section>
    )
}

export default GivingBack