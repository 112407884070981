import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from 'react-bootstrap'
import "./council.scss"


const CouncilTitans = ({ className }) => {

    const { t } = useTranslation("about", { keyPrefix: "CouncilTitans" })
    return (
        <section id="counciloftitans" className={(className ? className + " " : "") + "council-titans"}>
            <Container fluid>
                <div className="max-container-sections">
                    <Row>
                        <Col className="councilContainer" xl={12} lg={12} md={12} sm={12} xs={12}>
                            <h6 className="header-square blue md-blue md-large">{t("Council of Titans")}</h6>
                            <h1>{t("We Have a Council of Titans")} </h1>
                            <p>{t("Justice means the respect of our values and culture")}<br></br><br></br>

                                {t("The council of titans' decisions are final")}<br></br><br></br>

                                {t("This is our judicial power")}

                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default CouncilTitans
