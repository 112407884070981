import * as React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import "./constitution.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Constitution = ({ className }) => {
    const { t } = useTranslation("about", { keyPrefix: "Constitution" })
    return (
        <section id="ourconstitution" className={(className ? className + " " : "") + "constitution"}>
            <Container fluid>
                <Row>
                    <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="line-constitution"></div>
                        <div className="logo-constitution"></div>
                        <span className="constitution-title text-vollkorn">{t("Constitution")}  <br></br> <span className="of">{t("of")}</span>  <br></br> {t("Blue Trail Software")}</span>
                        <p className="text-volkhov">
                            <span className="first-constitution">{t("Nurturing our culture and values should remain the one fundamental focus of all our employees, and even more so of our company leaders. As our company continues to grow rapidly, the risk of departing from this core focus also increases, as we will inevitably continue to create more rules, more processes, and more structure.")}</span>   <br></br><br></br>
                            <span className="second-constitution">
                                {t("To prevent this risk, we wrote a constitution, inspired by the US one, that has become the backbone of our corporate organization. We have a judicial, executive, and legislative power, with a system of checks and balances among them.")}</span><br></br><br></br>
                            <span className="third-constitution">
                                {t("Distributing power across the entire organization is our way to prevent abuse of power and increase overall performance. Besides profitability, our primary focus should be and remain the empowerment of our stakeholders; this is fundamental.")}</span><br></br><br></br>
                            <span className="text-constitution text-volkhov"> {t("The main benefit for our clients is the access to a disciplined, diverse, and engaged workforce.")}</span><br></br><br></br>
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Constitution
